
	import { Vue, Component } from 'vue-property-decorator';
	import { checkGyroscopeAccess } from '@/Utility/Helpers';

	@Component
	export default class CardTiltComponent extends Vue {
		/**
		 * @type number
		 */
		private MAX_TILT_X: number = 50;

		/**
		 * @type number
		 */
		private MAX_TILT_Y: number = 50;

		/**
		 * @type string
		 */
		private cardRarity: string = 'common holo';

		/**
		 * @type number
		 */
		private degreesX: number = 0;

		/**
		 * @type number
		 */
		private degreesY: number = 0;

		/**
		 * @type number
		 */
		private initialTiltX: number | null = null;

		/**
		 * @type number
		 */
		private initialTiltY: number | null = null;

		/**
		 * @type boolean
		 */
		private isDevice: boolean = false;

		/**
		 * @return Record<string, string>
		 */
		get cardStyle() {
			return {
				'--rotate-x': `${this.degreesX}deg`,
				'--rotate-y': `${this.degreesY}deg`,
			};
		}

		/**
		 * @return void
		 */
		mounted() {
			checkGyroscopeAccess((hasGyroAccess) => {
				hasGyroAccess ? this.setupTilt() : this.setupPointer();
			});
		}

		/**
		 * @return void
		 */
		private setupTilt() {
			this.isDevice = true;
			window.addEventListener('deviceorientation', this.handleDeviceOrientation, true);
		}

		/**
		 * @return void
		 */
		private handleDeviceOrientation(e: DeviceOrientationEvent) {
			const { beta: x, gamma: y } = e;

			if (this.initialTiltX === null || this.initialTiltY === null) {
				this.initialTiltX = x;
				this.initialTiltY = y;
			}

			this.calculateTilt({
				x,
				y,
			});
		}

		/**
		 * @return void
		 */
		private calculateTilt({ x, y }: { x: number | null; y: number | null }): void {
			if (x === null || y === null) return;

			let tiltX = x - (this.initialTiltX ?? 0);
			let tiltY = y - (this.initialTiltY ?? 0);

			tiltX = Math.max(Math.min(tiltX, this.MAX_TILT_X), -this.MAX_TILT_X);
			tiltY = Math.max(Math.min(tiltY, this.MAX_TILT_Y), -this.MAX_TILT_Y);

			this.degreesX = tiltX;
			this.degreesY = tiltY * -1;
		}

		/**
		 * @param PointerEvent e
		 * @return void
		 */
		private setupPointer() {
			window.addEventListener('pointermove', (e: PointerEvent) => {
				const { clientX, clientY } = e;

				const width = window.innerWidth;
				const height = window.innerHeight;

				const ratioX = clientX / width;
				const ratioY = clientY / height;

				this.degreesX = (ratioX - 0.5) * this.MAX_TILT_X;
				this.degreesY = (ratioY - 0.5) * -this.MAX_TILT_Y;
			});
		}
	}
